<template>
<div class="uk-width-1-1">
  <div class="uk-background-default uk-padding-small">
    <div class="uk-form-stacked">
      <div class="uk-width-medium uk-margin">
        <label class="uk-form-label">Schnittstelle</label>
        <select class="uk-select" v-model="form.api">
          <option value="webkfz">Webkfz</option>
          <option value="aos">AOS</option>
           <option value="eln">ELN</option>
        </select>
      </div>
      <div class="uk-margin">
        <div class="uk-grid uk-child-width-1-3@m" uk-grid>
          <div>
            <label>
              <input type="checkbox" class="uk-checkbox" v-model="form.showLogos">
              Zeige Hersteller Logos
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  components: {

  },
  data () {
    return {
      form: {}
    }
  },
  mounted () {
    this.form = this.data
  }
}
</script>
